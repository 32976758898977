<template>
    <v-dialog
        v-model="selectorDialog"
        max-width="250px"
        persistent
        width="250px"
    >
        <v-form ref="productForm" lazy-validation>
            <v-card>
                <v-card-title class="px-4">{{ $t('message.addProduct') }}</v-card-title>
                <v-card-text class="px-4">
                    <v-autocomplete
                        :items="options.products"
                        :loading="loading.options"
                        :placeholder="$t('message.typeToSearch')"
                        :rules="[...validationRules.required]"
                        clearable
                        dense
                        hide-details="auto"
                        item-text="ProductProperty.title"
                        item-value="ProductProperty.id"
                        ref="productAutocomplete"
                        return-object
                        solo
                        v-model="selectedProduct"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn class="ml-2" @click="closeSelector" small>{{ $t('message.cancel') }}</v-btn>
                    <v-btn @click="addProduct" :loading="loading.add" small color="info">{{ $t('message.add') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>

import {api} from "Api";
import {validationRules} from "@/store/modules/appic/constants";
import {mapFields} from "vuex-map-fields";

export default {
    name: 'ProductSelector',
    props: ['supplierId','openDialog','customerId'],
    data() {
        return {
            dialog: false,
            loading: {
                add: false,
                options: false
            },
            selectedProduct: null
        }
    },
    computed: {
        ...mapFields('supplier',{
            options: 'options'
        }),
        validationRules() {
            return validationRules
        },
        selectorDialog: {
            get() {
                return this.dialog;
            },
            set(value){
                this.dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        addProduct() {
            this.$refs.productAutocomplete.blur()
            this.$nextTick(() => {
                if(this.$refs.productForm.validate()) {
                    this.loading.add = true
                    const product = {
                        customer_id: parseInt(this.customerId),
                        supplier_id: parseInt(this.supplierId),
                        productgroup_id: parseInt(this.selectedProduct['ProductProperty']['id'])
                    }
                    api
                        .post('/products', {
                            product: product
                        })
                        .then((response) => {
                            if (response.data.status == 'success') {
                                this.$toast.success(this.$t('message.successes.productAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.$emit('product-added')
                            } else {
                                this.$toast.error(this.$t('message.errors.productNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.add = false
                            this.closeSelector()
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.productNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        })
                }
            })
        },
        closeSelector(){
            this.$refs.productForm.reset()
            this.selectorDialog = false
            this.selectedProduct = null
        },
        loadList() {
            this.loading.options = 'warning'
            const property = 'Product.product'
            api
                .get('/products/properties/' + property + '/options',{
                    params: {
                        conditions: [
                            {
                                field: 'supplier_id',
                                value: this.supplierId
                            }
                        ]
                    }
                })
                .then((response) => {
                    if(response.data.status == 'success'){
                        this.options.products = response.data.data
                    } else {
                        this.$toast.error(this.$t('message.errors.optionsNotLoaded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                    this.loading.options = false
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.optionsNotLoaded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.options = false
                })
        }
    },
    watch: {
        openDialog(value) {
            if(this.options.products.length == 0) this.loadList()
            this.selectedProduct = null
            this.dialog = value
        }
    },
    mounted() {
        if(this.options.products.length == 0) this.loadList()
    }
}

</script>

<style scoped>

</style>